@charset "UTF-8";
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Noto+Sans+JP:wght@400;700;900&family=Oswald:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none;
  vertical-align: top; }

table {
  border-collapse: collapse; }

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

textarea {
  overflow: auto;
  resize: vertical; }

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0; }

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/* font */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@media screen and (max-width: 980px) {
  body {
    font-weight: 400;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 14px; }
  ul {
    list-style: none; }
  #header {
    height: 65px; }
    #header.fixed, #header.second {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 65px;
      background-color: #fff;
      z-index: 99; }
      #header.fixed .header_inner, #header.second .header_inner {
        height: 100%; }
      #header.fixed .header_inner .logo, #header.second .header_inner .logo {
        height: 100%;
        display: block;
        padding: 15px 0 15px 20px; }
        #header.fixed .header_inner .logo img, #header.second .header_inner .logo img {
          height: 100%; }
    #header .header_inner {
      position: relative; }
      #header .header_inner #menu_open {
        border: 1px solid #00a3a8;
        border-radius: 50%;
        width: 37px;
        height: 37px;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 2;
        transition: .5s; }
        #header .header_inner #menu_open span {
          background-color: #00a3a8;
          height: 1px;
          display: block;
          position: absolute;
          transition: .5s; }
          #header .header_inner #menu_open span:nth-of-type(1) {
            width: 17px;
            top: 15px;
            right: 9px; }
          #header .header_inner #menu_open span:nth-of-type(2) {
            width: 11px;
            top: 20px;
            right: 9px; }
      #header .header_inner #menu_close {
        border: 1px solid #00a3a8;
        border-radius: 50%;
        width: 37px;
        height: 37px;
        position: absolute;
        top: 15px;
        right: 15px;
        transition: .5s;
        border: 1px solid #fff; }
        #header .header_inner #menu_close span {
          background-color: #fff;
          height: 1px;
          display: block;
          position: absolute;
          transition: .5s; }
          #header .header_inner #menu_close span:nth-of-type(1) {
            width: 17px;
            top: 18px;
            right: 9px;
            transform: rotate(25deg); }
          #header .header_inner #menu_close span:nth-of-type(2) {
            width: 17px;
            top: 18px;
            right: 9px;
            transform: rotate(-25deg); }
      #header .header_inner .logo {
        display: none; }
    #header .nav_wrap {
      display: none;
      background-color: #00a3a8;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 98;
      padding: 25px 20px; }
      #header .nav_wrap .sp_logo {
        width: 64px; }
        #header .nav_wrap .sp_logo img {
          width: 100%; }
      #header .nav_wrap .gnav {
        margin-top: 50px; }
        #header .nav_wrap .gnav ul li:not(first-of-type) {
          margin-top: 30px; }
        #header .nav_wrap .gnav ul a {
          text-decoration: none;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          display: block; }
          #header .nav_wrap .gnav ul a span:nth-of-type(2) {
            display: none; }
      #header .nav_wrap .tel_wrap {
        margin-top: 50px;
        display: inline-block; }
        #header .nav_wrap .tel_wrap a {
          font-family: "Lato", sans-serif;
          text-decoration: none;
          font-weight: bold;
          position: relative;
          display: inline-block;
          color: #fff;
          font-size: 17px;
          padding: 10px 20px 10px 40px;
          border-radius: 25px;
          border: 1px solid #fff; }
          #header .nav_wrap .tel_wrap a img {
            margin-top: -5px; }
          #header .nav_wrap .tel_wrap a.tel:before {
            content: "";
            display: inline-block;
            background: url(../images/common/icon_tel_w.png) no-repeat;
            background-size: cover;
            width: 12px;
            height: 22px;
            position: absolute;
            left: 19px;
            top: 10px; }
    #header .sns {
      display: inline-block;
      margin-left: 25px;
      margin-top: 50px;
      transform: translateY(5px); }
      #header .sns a {
        width: 24px;
        height: 24px;
        display: inline-block; }
        #header .sns a:not(:first-of-type) {
          margin-left: 15px; }
        #header .sns a.line {
          background: url(../images/common/icon_line_w.png) no-repeat 0 0;
          background-size: cover; }
        #header .sns a.insta {
          background: url(../images/common/icon_insta_w.png) no-repeat 0 0;
          background-size: cover; }
        #header .sns a.facebook {
          background: url(../images/common/icon_fb_w.png) no-repeat 0 0;
          background-size: cover; }
  .float_line {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 2; }
    .float_line img {
      width: 80px; }
  .sidebar {
    display: none; }
  #pageTop {
    width: 45px;
    height: 45px;
    border: 1px solid #00a3a8;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 0; }
    #pageTop:before {
      content: '';
      width: 10px;
      height: 10px;
      border: 0px;
      border-top: solid 1px #00a3a8;
      border-left: solid 1px #00a3a8;
      transform: rotate(45deg);
      position: absolute;
      top: calc(50% - 3px);
      right: 16px; }
  #footer {
    background: url(../images/common/footer_bg_sp.png) no-repeat center top;
    background-size: cover;
    padding-top: 60px;
    padding-bottom: 40px;
    position: relative; }
    #footer .footer_inner {
      padding: 0 20px; }
    #footer .footer_top .footer_info .title {
      font-size: 15px;
      font-weight: bold;
      margin: 0; }
    #footer .footer_top .footer_info .text {
      font-size: 12px;
      margin: 20px 0 0 0; }
    #footer .footer_top .footer_nav {
      margin-top: 45px; }
      #footer .footer_top .footer_nav ul {
        display: flex;
        flex-wrap: wrap; }
        #footer .footer_top .footer_nav ul li {
          width: 50%; }
          #footer .footer_top .footer_nav ul li:nth-of-type(n + 3) {
            margin-top: 20px; }
        #footer .footer_top .footer_nav ul a {
          text-decoration: none;
          color: #000;
          font-size: 12px;
          font-weight: bold; }
          #footer .footer_top .footer_nav ul a span:nth-of-type(2) {
            display: none; }
    #footer .footer_bottom {
      margin-top: 45px;
      display: flex;
      flex-direction: column-reverse; }
      #footer .footer_bottom .copyright {
        font-family: "Lato", sans-serif;
        color: #868686;
        letter-spacing: 0.1em;
        margin-top: 45px;
        font-size: 10px; }
      #footer .footer_bottom .sub_link ul li:not(:first-of-type) {
        margin-top: 15px; }
      #footer .footer_bottom .sub_link ul a {
        text-decoration: none;
        color: #000;
        font-size: 11px; }
      #footer .footer_bottom .sub_link .sns {
        margin-top: 45px; }
        #footer .footer_bottom .sub_link .sns a {
          display: inline-block;
          width: 30px;
          height: 30px; }
          #footer .footer_bottom .sub_link .sns a:not(:first-of-type) {
            margin-left: 20px; }
          #footer .footer_bottom .sub_link .sns a.line {
            background: url(../images/common/icon_line.png) no-repeat 0 0;
            background-size: cover; }
            #footer .footer_bottom .sub_link .sns a.line:hover {
              background: url(../images/common/icon_line_blue.png) no-repeat 0 0; }
          #footer .footer_bottom .sub_link .sns a.insta {
            background: url(../images/common/icon_insta.png) no-repeat 0 0;
            background-size: cover; }
            #footer .footer_bottom .sub_link .sns a.insta:hover {
              background: url(../images/common/icon_insta_blue.png) no-repeat 0 0; }
          #footer .footer_bottom .sub_link .sns a.facebook {
            background: url(../images/common/icon_fb.png) no-repeat 0 0;
            background-size: cover; }
            #footer .footer_bottom .sub_link .sns a.facebook:hover {
              background: url(../images/common/icon_fb_blue.png) no-repeat 0 0; }
  .note_list .item:first-of-type {
    border-top: 1px solid #000; }
  .note_list .item a {
    text-decoration: none;
    color: #000;
    position: relative;
    border-bottom: 1px solid #000;
    padding: 30px 0px 40px 20px;
    display: flex; }
    .note_list .item a:after {
      content: "";
      display: inline-block;
      background: url(../images/common/arrow.png) no-repeat;
      width: 11px;
      height: 12px;
      position: absolute;
      right: 15px;
      bottom: 20px;
      transition: 0.3s;
      right: 10px;
      bottom: 15px; }
  .note_list .item .ymd {
    position: absolute;
    color: #fff;
    font-family: "Oswald", sans-serif;
    background-color: #00a3a8;
    display: inline-block;
    text-align: center;
    left: 0px;
    top: 20px;
    padding: 10px 5px; }
    .note_list .item .ymd span {
      display: block;
      line-height: 1;
      letter-spacing: 0.1em; }
    .note_list .item .ymd .y {
      font-size: 10px; }
    .note_list .item .ymd .m {
      font-size: 12px;
      margin-top: 7px; }
    .note_list .item .ymd .d {
      border-top: 1px solid #fff;
      font-size: 12px;
      padding-top: 5px;
      margin-top: 5px; }
  .note_list .item figure {
    width: 100px; }
    .note_list .item figure img {
      width: 100%; }
  .note_list .item .text_wrap {
    width: calc(100% - 100px);
    padding-left: 20px; }
    .note_list .item .text_wrap .title {
      font-weight: bold;
      margin: 0;
      line-height: 1.3;
      font-size: 14px; }
    .note_list .item .text_wrap .text {
      font-weight: bold;
      font-size: 11px;
      margin-top: 20px; }
  .fade-in {
    opacity: 0;
    transition-duration: 1000ms;
    transition-property: opacity, transform;
    transform: translate(0, 50px); }
  .scroll-in {
    opacity: 1;
    transform: translate(0, 0); }
  /* ココから下には記入禁止 */
  .forPC {
    display: none !important; } }

@media screen and (min-width: 980px) {
  body {
    font-weight: 400;
    font-family: "Noto Sans JP", sans-serif; }
  ul {
    list-style: none; }
  #header .header_inner .logo {
    display: none; }
  #header .nav_wrap .gnav ul a {
    text-decoration: none; }
  #header .nav_wrap .tel_wrap a {
    font-family: "Lato", sans-serif;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    display: inline-block; }
  #footer .footer_top .footer_nav ul a {
    text-decoration: none;
    color: #000; }
  #footer .footer_bottom .copyright {
    font-family: "Lato", sans-serif;
    color: #868686;
    letter-spacing: 0.1em; }
  #footer .footer_bottom .sub_link ul a {
    text-decoration: none;
    color: #000; }
  #footer .footer_bottom .sub_link .sns a {
    display: inline-block; }
    #footer .footer_bottom .sub_link .sns a.line {
      background: url(../images/common/icon_line.png) no-repeat 0 0;
      background-size: cover; }
      #footer .footer_bottom .sub_link .sns a.line:hover {
        background: url(../images/common/icon_line_blue.png) no-repeat 0 0; }
    #footer .footer_bottom .sub_link .sns a.insta {
      background: url(../images/common/icon_insta.png) no-repeat 0 0;
      background-size: cover; }
      #footer .footer_bottom .sub_link .sns a.insta:hover {
        background: url(../images/common/icon_insta_blue.png) no-repeat 0 0; }
    #footer .footer_bottom .sub_link .sns a.facebook {
      background: url(../images/common/icon_fb.png) no-repeat 0 0;
      background-size: cover; }
      #footer .footer_bottom .sub_link .sns a.facebook:hover {
        background: url(../images/common/icon_fb_blue.png) no-repeat 0 0; }
  .note_list .item:first-of-type {
    border-top: 1px solid #000; }
  .note_list .item a {
    text-decoration: none;
    color: #000;
    position: relative; }
    .note_list .item a:after {
      content: "";
      display: inline-block;
      background: url(../images/common/arrow.png) no-repeat;
      width: 11px;
      height: 12px;
      position: absolute;
      right: 15px;
      bottom: 20px;
      transition: 0.3s; }
  .note_list .item .ymd {
    position: absolute;
    color: #fff;
    font-family: "Oswald", sans-serif;
    background-color: #00a3a8;
    display: inline-block;
    text-align: center; }
    .note_list .item .ymd span {
      display: block;
      line-height: 1;
      letter-spacing: 0.1em; }
    .note_list .item .ymd .d {
      border-top: 1px solid #fff; }
  .note_list .item figure img {
    width: 100%; }
  .note_list .item .text_wrap .title {
    font-weight: bold;
    margin: 0;
    line-height: 1.3; }
  .note_list .item .text_wrap .text {
    font-weight: bold; }
  .fade-in {
    opacity: 0;
    transition-duration: 1000ms;
    transition-property: opacity, transform;
    transform: translate(0, 50px); }
  .scroll-in {
    opacity: 1;
    transform: translate(0, 0); }
  /* ココから下には記入禁止 */ }

@media print, screen and (min-width: 981px) {
  body {
    font-weight: 400;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px; }
  ul {
    list-style: none; }
  #header.fixed, #header.second {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 130px;
    background-color: #fff;
    z-index: 99; }
    #header.fixed .header_inner, #header.second .header_inner {
      height: 100%;
      padding: 0 140px 0 40px;
      display: flex;
      align-items: center; }
    #header.fixed .header_inner .logo, #header.second .header_inner .logo {
      display: block; }
      #header.fixed .header_inner .logo img, #header.second .header_inner .logo img {
        width: 64px; }
    #header.fixed .nav_wrap, #header.second .nav_wrap {
      display: flex;
      align-items: center; }
      #header.fixed .nav_wrap .tel_wrap, #header.second .nav_wrap .tel_wrap {
        margin-top: 0;
        margin-left: 30px; }
        #header.fixed .nav_wrap .tel_wrap a, #header.second .nav_wrap .tel_wrap a {
          background-color: #f6f6f6; }
  #header .header_inner {
    position: relative;
    display: flex;
    padding-top: 50px;
    padding-right: calc(8vw + 60px);
    z-index: 2; }
    #header .header_inner .logo {
      display: none; }
  #header .nav_wrap {
    margin: 0 0 0 auto; }
    #header .nav_wrap .sp_logo {
      display: none; }
    #header .nav_wrap .gnav ul {
      display: flex; }
      #header .nav_wrap .gnav ul li:not(first-of-type) {
        margin-left: 40px; }
      #header .nav_wrap .gnav ul a {
        text-decoration: none;
        color: #000;
        font-size: 17px;
        font-weight: bold;
        display: block;
        overflow: hidden;
        position: relative;
        backface-visibility: hidden; }
        #header .nav_wrap .gnav ul a span {
          display: block;
          transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1), -webkit-transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
          backface-visibility: hidden; }
          #header .nav_wrap .gnav ul a span:nth-of-type(2) {
            position: absolute;
            bottom: 0;
            left: 0;
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
        #header .nav_wrap .gnav ul a:hover span:nth-of-type(1) {
          transform: translateY(100%); }
        #header .nav_wrap .gnav ul a:hover span:nth-of-type(2) {
          transform: translateY(0); }
    #header .nav_wrap .tel_wrap {
      text-align: right;
      margin-top: 20px; }
      #header .nav_wrap .tel_wrap a {
        font-family: "Lato", sans-serif;
        text-decoration: none;
        font-weight: bold;
        position: relative;
        display: inline-block;
        color: #000;
        pointer-events: none;
        font-size: 23px;
        padding: 20px 20px 20px 60px;
        border-radius: 10px;
        background-color: #fff; }
        #header .nav_wrap .tel_wrap a.tel:before {
          content: "";
          display: inline-block;
          background: url(../images/common/icon_tel.png) no-repeat;
          background-size: cover;
          width: 18px;
          height: 33px;
          position: absolute;
          left: 25px;
          top: 17px; }
  #header .sns {
    position: absolute;
    right: 85px;
    top: 50px; }
    #header .sns a {
      width: 30px;
      height: 30px;
      display: block;
      transition: .3s; }
      #header .sns a:not(:first-of-type) {
        margin-top: 20px; }
      #header .sns a.line {
        background: url(../images/common/icon_line.png) no-repeat 0 0;
        background-size: cover; }
        #header .sns a.line:hover {
          background: url(../images/common/icon_line_blue.png) no-repeat 0 0; }
      #header .sns a.insta {
        background: url(../images/common/icon_insta.png) no-repeat 0 0;
        background-size: cover; }
        #header .sns a.insta:hover {
          background: url(../images/common/icon_insta_blue.png) no-repeat 0 0; }
      #header .sns a.facebook {
        background: url(../images/common/icon_fb.png) no-repeat 0 0;
        background-size: cover; }
        #header .sns a.facebook:hover {
          background: url(../images/common/icon_fb_blue.png) no-repeat 0 0; }
  .float_line {
    position: fixed;
    right: 75px;
    bottom: 25px;
    z-index: 2; }
    .float_line a {
      display: inline-block;
      transition: .3s; }
      .float_line a:hover {
        opacity: .7; }
  .sidebar {
    color: #fff;
    font-family: "Lato", sans-serif;
    font-size: 11px;
    font-weight: bold;
    width: 60px;
    height: 100%;
    background-color: #00a3a8;
    position: fixed;
    top: 0;
    right: 0;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1em;
    z-index: 100; }
  #pageTop {
    font-size: 13px;
    position: fixed;
    bottom: 30px;
    right: 0;
    cursor: pointer;
    display: none;
    width: 60px;
    padding-top: 110px;
    padding-right: 21px;
    z-index: 100;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    font-family: "Lato", sans-serif;
    color: #fff; }
    #pageTop:before {
      content: "";
      width: 1px;
      height: 100px;
      display: inline-block;
      background-color: #fff;
      position: absolute;
      top: 0px;
      left: 50%; }
  #footer {
    background: url(../images/common/footer_bg.png) no-repeat center top;
    background-size: cover;
    padding-top: 80px;
    padding-bottom: 80px; }
    #footer .footer_inner {
      width: 96%;
      max-width: 1260px;
      margin: 0 auto;
      padding: 0 60px 0 0; }
    #footer .footer_top {
      display: flex;
      justify-content: space-between; }
      #footer .footer_top .footer_info .title {
        font-size: 16px;
        font-weight: bold;
        margin: 0; }
      #footer .footer_top .footer_info .text {
        font-size: 14px;
        line-height: 2;
        margin: 20px 0 0 0; }
      #footer .footer_top .footer_nav ul {
        display: flex; }
        #footer .footer_top .footer_nav ul li:not(:first-of-type) {
          margin-left: 35px; }
        #footer .footer_top .footer_nav ul a {
          text-decoration: none;
          color: #000;
          font-size: 14px;
          font-weight: bold;
          display: block;
          overflow: hidden;
          position: relative;
          backface-visibility: hidden; }
          #footer .footer_top .footer_nav ul a span {
            display: block;
            transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1), -webkit-transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
            backface-visibility: hidden; }
            #footer .footer_top .footer_nav ul a span:nth-of-type(2) {
              position: absolute;
              bottom: 0;
              left: 0;
              -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
          #footer .footer_top .footer_nav ul a:hover span:nth-of-type(1) {
            transform: translateY(100%); }
          #footer .footer_top .footer_nav ul a:hover span:nth-of-type(2) {
            transform: translateY(0); }
    #footer .footer_bottom {
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      #footer .footer_bottom .copyright {
        font-family: "Lato", sans-serif;
        color: #868686;
        letter-spacing: 0.1em;
        font-size: 12px; }
      #footer .footer_bottom .sub_link {
        display: flex;
        align-items: center; }
        #footer .footer_bottom .sub_link ul {
          display: flex; }
          #footer .footer_bottom .sub_link ul li:not(:first-of-type) {
            margin-left: 20px; }
          #footer .footer_bottom .sub_link ul a {
            text-decoration: none;
            color: #000;
            font-size: 14px; }
            #footer .footer_bottom .sub_link ul a:hover {
              text-decoration: underline; }
        #footer .footer_bottom .sub_link .sns {
          margin-left: 30px;
          padding-top: 5px; }
          #footer .footer_bottom .sub_link .sns a {
            display: inline-block;
            width: 30px;
            height: 30px;
            transition: .3s; }
            #footer .footer_bottom .sub_link .sns a:not(:first-of-type) {
              margin-left: 20px; }
            #footer .footer_bottom .sub_link .sns a.line {
              background: url(../images/common/icon_line.png) no-repeat 0 0;
              background-size: cover; }
              #footer .footer_bottom .sub_link .sns a.line:hover {
                background: url(../images/common/icon_line_blue.png) no-repeat 0 0; }
            #footer .footer_bottom .sub_link .sns a.insta {
              background: url(../images/common/icon_insta.png) no-repeat 0 0;
              background-size: cover; }
              #footer .footer_bottom .sub_link .sns a.insta:hover {
                background: url(../images/common/icon_insta_blue.png) no-repeat 0 0; }
            #footer .footer_bottom .sub_link .sns a.facebook {
              background: url(../images/common/icon_fb.png) no-repeat 0 0;
              background-size: cover; }
              #footer .footer_bottom .sub_link .sns a.facebook:hover {
                background: url(../images/common/icon_fb_blue.png) no-repeat 0 0; }
  .note_list .item:first-of-type {
    border-top: 1px solid #000; }
  .note_list .item a {
    text-decoration: none;
    color: #000;
    position: relative;
    border-bottom: 1px solid #000;
    padding: 35px 15px 35px 30px;
    display: flex; }
    .note_list .item a:hover:after {
      right: 10px;
      bottom: 25px; }
    .note_list .item a:hover .text_wrap {
      color: #00a3a8; }
    .note_list .item a:after {
      content: "";
      display: inline-block;
      background: url(../images/common/arrow.png) no-repeat;
      width: 11px;
      height: 12px;
      position: absolute;
      right: 15px;
      bottom: 20px;
      transition: 0.3s; }
  .note_list .item .ymd {
    position: absolute;
    color: #fff;
    font-family: "Oswald", sans-serif;
    background-color: #00a3a8;
    display: inline-block;
    text-align: center;
    left: 0px;
    top: 25px;
    padding: 13px; }
    .note_list .item .ymd span {
      display: block;
      line-height: 1;
      letter-spacing: 0.1em; }
    .note_list .item .ymd .y {
      font-size: 13px; }
    .note_list .item .ymd .m {
      font-size: 19px;
      margin-top: 10px; }
    .note_list .item .ymd .d {
      border-top: 1px solid #fff;
      font-size: 19px;
      padding-top: 10px;
      margin-top: 10px; }
  .note_list .item figure {
    width: 170px; }
    .note_list .item figure img {
      width: 100%; }
  .note_list .item .text_wrap {
    width: calc(100% - 170px);
    padding-left: 40px;
    transition: 0.3s; }
    .note_list .item .text_wrap .title {
      font-weight: bold;
      margin: 0;
      line-height: 1.3;
      font-size: 25px; }
    .note_list .item .text_wrap .text {
      font-weight: bold;
      font-size: 16px;
      margin-top: 20px; }
  .fade-in {
    opacity: 0;
    transition-duration: 1000ms;
    transition-property: opacity, transform;
    transform: translate(0, 50px); }
  .scroll-in {
    opacity: 1;
    transform: translate(0, 0); }
  /* ココから下には記入禁止 */
  .forSP {
    display: none !important; } }
