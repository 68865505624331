body{
	font-weight: 400;
    font-family: $ff_jp;
    @if $type == sp{
        font-size: 14px;
    }
    @if $type == pc{
        font-size: 16px;
    }
}
ul{
    list-style: none;
}
#header{
    @if $type == sp{
        height: 65px;
    }
    @if $type == pc{
    }
    //topのスクロールと下層ヘッダー用
    &.fixed,&.second{
        @if $type == sp{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 65px;
            background-color: #fff;
            z-index: 99;
            .header_inner{
                height: 100%;
            }
            .header_inner .logo{
                height: 100%;
                display: block;
                padding: 15px 0 15px 20px;
                img{
                    height: 100%;
                }
            }
        }
        @if $type == pc{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 130px;
            background-color: #fff;
            z-index: 99;
            .header_inner{
                height: 100%;
                padding: 0 140px 0 40px;
                display: flex;
                align-items: center;
            }
            .header_inner .logo{
                display: block;
                img{
                    width: 64px;
                }
            }
            .nav_wrap{
                display: flex;
                align-items: center;
                .tel_wrap{
                    margin-top: 0;
                    margin-left: 30px;
                    a{
                        background-color: #f6f6f6;
                    }
                }
            }
        }
    }
    .header_inner{
        @if $type == sp{
            position: relative;
            #menu_open{
                border: 1px solid $c_base;
                border-radius: 50%;
                width: 37px;
                height: 37px;
                position: absolute;
                top: 15px;
                right: 15px;
                z-index: 2;
                transition: .5s;
                span{
                    background-color: $c_base;
                    height: 1px;
                    display: block;
                    position: absolute;
                    transition: .5s;
                    &:nth-of-type(1){
                        width: 17px;
                        top: 15px;
                        right: 9px;
                    }
                    &:nth-of-type(2){
                        width: 11px;
                        top: 20px;
                        right: 9px;
                    }
                }
            }
            #menu_close{
                border: 1px solid $c_base;
                border-radius: 50%;
                width: 37px;
                height: 37px;
                position: absolute;
                top: 15px;
                right: 15px;
                transition: .5s;
                border: 1px solid #fff;
                span{
                    background-color: #fff;
                    height: 1px;
                    display: block;
                    position: absolute;
                    transition: .5s;
                    &:nth-of-type(1){
                        width: 17px;
                        top: 18px;
                        right: 9px;
                        transform: rotate(25deg);
                    }
                    &:nth-of-type(2){
                        width: 17px;
                        top: 18px;
                        right: 9px;
                        transform: rotate(-25deg);
                    }
                }
            }
        }
        @if $type == pc{
            position: relative;
            display: flex;
            padding-top: 50px;
            padding-right: calc(8vw + 60px);
            z-index: 2;
        }
        .logo{
            display: none;
        }
    }
    .nav_wrap{
        @if $type == sp{
            display: none;
            background-color: $c_base;
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 98;
            padding: 25px 20px;
            
        }
        @if $type == pc{
            margin: 0 0 0 auto;
        }
        .sp_logo{
            @if $type == sp{
                width: 64px;
                img{
                    width: 100%;
                }
            }
            @if $type == pc{
                display: none;
            }
        }
        .gnav{
            @if $type == sp{
                margin-top: 50px;
            }
            @if $type == pc{
            }
            ul{
                @if $type == sp{
                }
                @if $type == pc{
                    display: flex;
                }
                li{
                    @if $type == sp{
                        &:not(first-of-type){
                            margin-top: 30px;
                        }
                    }
                    @if $type == pc{
                        &:not(first-of-type){
                            margin-left: 40px;
                        }
                    }
                }
                a{
                    text-decoration: none;
                    @if $type == sp{
                        color: #fff;
                        font-size: 15px;
                        font-weight: bold;
                        display: block;
                        span:nth-of-type(2){
                            display: none;
                        }
                    }
                    @if $type == pc{
                        color: #000;
                        font-size: 17px;
                        font-weight: bold;
                        display: block;
                        overflow: hidden;
                        position: relative;
                        backface-visibility: hidden;
                        span{
                            display: block;
                            transition: transform .6s cubic-bezier(.85,0,.15,1),-webkit-transform .6s cubic-bezier(.85,0,.15,1);
                            backface-visibility: hidden;
                            &:nth-of-type(2){
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                -webkit-transform: translateY(-100%);
                                transform: translateY(-100%);
                            }
                        }
                        &:hover{
                            span{
                                &:nth-of-type(1){
                                    transform: translateY(100%);
                                }
                                &:nth-of-type(2){
                                    transform: translateY(0);
                                }
                            }
                        }
                    }
                }
            }
        }
        .tel_wrap{
            @if $type == sp{
                margin-top: 50px;
                display: inline-block;
            }
            @if $type == pc{
                text-align: right;
                margin-top: 20px;
            }
            a{
                font-family: $ff_en;
                text-decoration: none;
                font-weight: bold;
                position: relative;
                display: inline-block;
                @if $type == sp{
                    color: #fff;
                    font-size: 17px;
                    padding: 10px 20px 10px 40px;
                    border-radius: 25px;
                    border: 1px solid #fff;;
                    img{
                        margin-top: -5px;
                    }
                }
                @if $type == pc{
                    color: #000;
                    pointer-events: none;
                    font-size: 23px;
                    padding: 20px 20px 20px 60px;
                    border-radius: 10px;
                    background-color: #fff;
                }
                &.tel:before{
                    @if $type == sp{
                        content: "";
                        display: inline-block;
                        background: url(../images/common/icon_tel_w.png) no-repeat;
                        background-size: cover;
                        width: 12px;
                        height: 22px;
                        position: absolute;
                        left: 19px;
                        top: 10px;
                    }
                    @if $type == pc{
                        content: "";
                        display: inline-block;
                        background: url(../images/common/icon_tel.png) no-repeat;
                        background-size: cover;
                        width: 18px;
                        height: 33px;
                        position: absolute;
                        left: 25px;
                        top: 17px;
                    }
                }
            }
        }
    }
    .sns{
        @if $type == sp{
            display: inline-block;
            margin-left: 25px;
            margin-top: 50px;
            transform: translateY(5px);
        }
        @if $type == pc{
            position: absolute;
            right: 85px;
            top: 50px;
        }
        a{
            @if $type == sp{
                width: 24px;
                height: 24px;
                display: inline-block;
                &:not(:first-of-type){
                    margin-left: 15px;
                }
                &.line{
                    background: url(../images/common/icon_line_w.png) no-repeat 0 0;
                    background-size: cover;
                }
                &.insta{
                    background: url(../images/common/icon_insta_w.png) no-repeat 0 0;
                    background-size: cover;
                }
                &.facebook{
                    background: url(../images/common/icon_fb_w.png) no-repeat 0 0;
                    background-size: cover;
                }
            }
            @if $type == pc{
                width: 30px;
                height: 30px;
                display: block;
                transition: .3s;
                &:not(:first-of-type){
                    margin-top: 20px;
                }
                &.line{
                    background: url(../images/common/icon_line.png) no-repeat 0 0;
                    background-size: cover;
                    &:hover{
                        background: url(../images/common/icon_line_blue.png) no-repeat 0 0;
                    }
                }
                &.insta{
                    background: url(../images/common/icon_insta.png) no-repeat 0 0;
                    background-size: cover;
                    &:hover{
                        background: url(../images/common/icon_insta_blue.png) no-repeat 0 0;
                    }
                }
                &.facebook{
                    background: url(../images/common/icon_fb.png) no-repeat 0 0;
                    background-size: cover;
                    &:hover{
                        background: url(../images/common/icon_fb_blue.png) no-repeat 0 0;
                    }
                }
            }
        }
    }
}//header

.float_line{
    @if $type == sp{
        position: fixed;
        right: 10px;
        bottom: 10px;
        z-index: 2;
        img{
            width: 80px;
        }
    }
    @if $type == pc{
        position: fixed;
        right: 75px;
        bottom: 25px;
        z-index: 2;
        a{
            display: inline-block;
            transition: .3s;
            &:hover{
                opacity: .7;
            }
        }
    }
}

.sidebar{
    @if $type == sp{
        display: none;
    }
    @if $type == pc{
        color: #fff;
        font-family: $ff_en;
        font-size: 11px;
        font-weight: bold;
        width: 60px;
        height: 100%;
        background-color: $c_base;
        position: fixed;
        top: 0;
        right: 0;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.1em;
        z-index: 100;
    }
}

#pageTop{
    @if $type == sp{
        width: 45px;
        height: 45px;
        border: 1px solid $c_base;
        border-radius: 50%;
        position: absolute;
        right: 20px;
        bottom: 20px;
        font-size: 0;
        &:before{
            content: '';
            width: 10px;
            height: 10px;
            border: 0px;
            border-top: solid 1px $c_base;
            border-left: solid 1px $c_base;
            transform: rotate(45deg);
            position: absolute;
            top: calc(50% - 3px);
            right: 16px;
        }
    }
    @if $type == pc{
        font-size: 13px;
        position: fixed;
        bottom: 30px;
        right: 0;
        cursor: pointer;
        display: none;
        width: 60px;
        padding-top: 110px;
        padding-right: 21px;
        z-index: 100;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        font-family: $ff_en;
        color: #fff;
        &:before{
            content: "";
            width: 1px;
            height: 100px;
            display: inline-block;
            background-color: #fff;
            position: absolute;
            top: 0px;
            left: 50%;
        }
    }
}

#footer{
    @if $type == sp{
        background: url(../images/common/footer_bg_sp.png) no-repeat center top;
        background-size: cover;
        padding-top: 60px;
        padding-bottom: 40px;
        position: relative;
    }
    @if $type == pc{
        background: url(../images/common/footer_bg.png) no-repeat center top;
        background-size: cover;
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .footer_inner{
        @if $type == sp{
            padding: 0 20px;
        }
        @if $type == pc{
            width: 96%;
            max-width: 1260px;
            margin: 0 auto;
            padding: 0 60px 0 0;
        }
    }
    .footer_top{
        @if $type == sp{
        }
        @if $type == pc{
            display: flex;
            justify-content: space-between;
        }
        .footer_info{
            @if $type == sp{
            }
            @if $type == pc{
            }
            .title{
                @if $type == sp{
                    font-size: 15px;
                    font-weight: bold;
                    margin: 0;
                }
                @if $type == pc{
                    font-size: 16px;
                    font-weight: bold;
                    margin: 0;
                }
            }
            .text{
                @if $type == sp{
                    font-size: 12px;
                    margin: 20px 0 0 0;
                }
                @if $type == pc{
                    font-size: 14px;
                    line-height: 2;
                    margin: 20px 0 0 0;
                }
            }
        }
        .footer_nav{
            @if $type == sp{
                margin-top: 45px;
            }
            @if $type == pc{
            }
            ul{
                @if $type == sp{
                    display: flex;
                    flex-wrap: wrap;
                }
                @if $type == pc{
                    display: flex;
                }
                li{
                    @if $type == sp{
                        width: 50%;
                        &:nth-of-type(n + 3){
                            margin-top: 20px;
                        }
                    }
                    @if $type == pc{
                        &:not(:first-of-type){
                            margin-left: 35px;
                        }
                    }
                }
                a{
                    text-decoration: none;
                    color: #000;
                    @if $type == sp{
                        font-size: 12px;
                        font-weight: bold;
                        span:nth-of-type(2){
                            display: none;
                        }
                    }
                    @if $type == pc{
                        font-size: 14px;
                        font-weight: bold;
                        display: block;
                        overflow: hidden;
                        position: relative;
                        backface-visibility: hidden;
                        span{
                            display: block;
                            transition: transform .6s cubic-bezier(.85,0,.15,1),-webkit-transform .6s cubic-bezier(.85,0,.15,1);
                            backface-visibility: hidden;
                            &:nth-of-type(2){
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                -webkit-transform: translateY(-100%);
                                transform: translateY(-100%);
                            }
                        }
                        &:hover{
                            span{
                                &:nth-of-type(1){
                                    transform: translateY(100%);
                                }
                                &:nth-of-type(2){
                                    transform: translateY(0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .footer_bottom{
        @if $type == sp{
            margin-top: 45px;
            display: flex;
            flex-direction: column-reverse;
        }
        @if $type == pc{
            margin-top: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .copyright{
            font-family: $ff_en;
            color: $c_gray;
            letter-spacing: 0.1em;
            @if $type == sp{
                margin-top: 45px;
                font-size: 10px;
            }
            @if $type == pc{
                font-size: 12px;
            }
        }
        .sub_link{
            @if $type == sp{
            }
            @if $type == pc{
                display: flex;
                align-items: center;
            }
            ul{
                @if $type == sp{
                }
                @if $type == pc{
                    display: flex;
                }
                li{
                    @if $type == sp{
                        &:not(:first-of-type){
                            margin-top: 15px;
                        }
                    }
                    @if $type == pc{
                        &:not(:first-of-type){
                            margin-left: 20px;
                        }
                    }
                }
                a{
                    text-decoration: none;
                    color: #000;
                    @if $type == sp{
                        font-size: 11px;
                    }
                    @if $type == pc{
                        font-size: 14px;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
            .sns{
                @if $type == sp{
                    margin-top: 45px;
                }
                @if $type == pc{
                    margin-left: 30px;
                    padding-top: 5px;
                }
                a{
                    display: inline-block;
                    @if $type == sp{
                        width: 30px;
                        height: 30px;
                        &:not(:first-of-type){
                            margin-left: 20px;
                        }
                    }
                    @if $type == pc{
                        width: 30px;
                        height: 30px;
                        transition: .3s;
                        &:not(:first-of-type){
                            margin-left: 20px;
                        }
                    }
                    &.line{
                        background: url(../images/common/icon_line.png) no-repeat 0 0;
                        background-size: cover;
                        &:hover{
                            background: url(../images/common/icon_line_blue.png) no-repeat 0 0;
                        }
                    }
                    &.insta{
                        background: url(../images/common/icon_insta.png) no-repeat 0 0;
                        background-size: cover;
                        &:hover{
                            background: url(../images/common/icon_insta_blue.png) no-repeat 0 0;
                        }
                    }
                    &.facebook{
                        background: url(../images/common/icon_fb.png) no-repeat 0 0;
                        background-size: cover;
                        &:hover{
                            background: url(../images/common/icon_fb_blue.png) no-repeat 0 0;
                        }
                    }
                }
            }
        }
    }
}

.note_list{
    @if $type == sp{
    }
    @if $type == pc{
    }
    .item{
        @if $type == sp{
        }
        @if $type == pc{
        }
        &:first-of-type{
            border-top: 1px solid #000;
        }
        a{
            text-decoration: none;
            color: #000;
            position: relative;
            @if $type == sp{
                border-bottom: 1px solid #000;
                padding: 30px 0px 40px 20px;
                display: flex;
            }
            @if $type == pc{
                border-bottom: 1px solid #000;
                padding: 35px 15px 35px 30px;
                display: flex;
                &:hover{
                    &:after{
                        right: 10px;
                        bottom: 25px;
                    }
                    .text_wrap{
                        color: $c_base;
                    }
                }
            }
            &:after{
                content: "";
                display: inline-block;
                background: url(../images/common/arrow.png) no-repeat;
                width: 11px;
                height: 12px;
                position: absolute;
                right: 15px;
                bottom: 20px;
                transition: 0.3s;
                @if $type == sp{
                    right: 10px;
                    bottom: 15px;
                }
            }
        }
        .ymd{
            position: absolute;
            color: #fff;
            font-family: $ff_oswald;
            background-color: $c_base;
            display: inline-block;
            text-align: center;
            @if $type == sp{
                left: 0px;
                top: 20px;
                padding: 10px 5px;
            }
            @if $type == pc{
                left: 0px;
                top: 25px;
                padding: 13px;
            }
            span{
                display: block;
                line-height: 1;
                letter-spacing: 0.1em;
            }
            .y{
                @if $type == sp{
                    font-size: 10px;
                }
                @if $type == pc{
                    font-size: 13px;
                }
            }
            .m{
                @if $type == sp{
                    font-size: 12px;
                    margin-top: 7px;
                }
                @if $type == pc{
                    font-size: 19px;
                    margin-top: 10px;
                }
            }
            .d{
                border-top: 1px solid #fff;
                @if $type == sp{
                    font-size: 12px;
                    padding-top: 5px;
                    margin-top: 5px;
                }
                @if $type == pc{
                    font-size: 19px;
                    padding-top: 10px;
                    margin-top: 10px;
                }
            }
        }
        figure{
            @if $type == sp{
                width: 100px;
            }
            @if $type == pc{
                width: 170px;
            }
            img{
                width: 100%;
            }
        }
        .text_wrap{
            @if $type == sp{
                width: calc(100% - 100px);
                padding-left: 20px;
            }
            @if $type == pc{
                width: calc(100% - 170px);
                padding-left: 40px;
                transition: 0.3s;
            }
            .title{
                font-weight: bold;
                margin: 0;
                line-height: 1.3;
                @if $type == sp{
                    font-size: 14px;
                }
                @if $type == pc{
                    font-size: 25px;
                }
            }
            .text{
                font-weight: bold;
                @if $type == sp{
                    font-size: 11px;
                    margin-top: 20px;
                }
                @if $type == pc{
                    font-size: 16px;
                    margin-top: 20px;
                }
            }
        }
    }
}

.fade-in {
    opacity: 0;
    transition-duration: 1000ms;
    transition-property: opacity, transform;
    transform: translate(0, 50px);
}

.scroll-in {
    opacity: 1;
    transform: translate(0, 0);
}


.sample{
	@if $type == sp{
	}
	@if $type == pc{
	}
}



/* ココから下には記入禁止 */

.forPC{	@if $type == sp{display: none!important;}}
.forSP{	@if $type == pc{display: none!important;}}
